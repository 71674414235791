import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';
import { GetJWTResult } from '../types';

const ROOT_KEY = 'users';

const userKeys = {
  userData: (userId: string) => [ROOT_KEY, userId] as const,
};

export const useUserDataQuery = (getJWT: () => Promise<GetJWTResult>, userId: string) => {
  return useQuery({
    queryKey: userKeys.userData(userId),
    queryFn: () => getApiService(getJWT).getUserData(userId),
    enabled: userId.length > 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
