import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, useContext } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import DialogConfirmation from '../../../components/DialogConfirmation/DialogConfirmation';
import { createStyles, makeStyles } from '@mui/styles';
import MicroFrontendContext from '../../MicroFrontendContext';
import { getApiService } from '../../../api/api-request';
import { ALLOWED_GROUPS, hasRequiredGroup } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import {
  CertificationStatus,
  ICertificationStatusDisplayContent,
  ICertificationTableItem,
  IIssuedCertificationTableItem,
} from '@shared/interfaces';
import { requestStatus } from './EventCheck';

const useStyles = makeStyles(() =>
  createStyles({
    certificateTable: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

interface ICertificateTableProps {
  certifications: ICertificationTableItem[];
  certificationStatus: ICertificationStatusDisplayContent | null | undefined;
  lernstandStatusLoading: requestStatus;
  certificationStatusRequestStatus: requestStatus;
  getLernstandServiceStatus: (userId: string) => void;
  setCertificationDialogItem: (certification: ICertificationTableItem & IIssuedCertificationTableItem) => void;
}

const CertificateTable: FunctionComponent<ICertificateTableProps> = ({
  certifications,
  certificationStatus,
  lernstandStatusLoading,
  getLernstandServiceStatus,
  setCertificationDialogItem,
  certificationStatusRequestStatus,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getJWT, groups } = useContext(MicroFrontendContext);

  const deleteCertificateItem = async (certificateId: string) => {
    try {
      const apiService = getApiService(getJWT);
      await apiService.deleteCertificate(certificateId);
    } catch (error) {
      // console.log(`Could not delete Certificate with id ${certificateId}`)
    }
  };

  const downloadCertificate = async (certificateId: string) => {
    try {
      const apiService = getApiService(getJWT);
      const certificateUrl = await apiService.getCertificateUrl(certificateId);
      if (certificateUrl) {
        window.open(certificateUrl.url, '_blank');
      }
    } catch (error) {}
  };

  const certificateTableActions = (certification: ICertificationTableItem) => {
    return (
      'certificateId' in certification && (
        <>
          <Tooltip title="Download" aria-label="add">
            <IconButton
              aria-label="download"
              size="small"
              onClick={() => downloadCertificate(certification.certificateId)}
            >
              <GetAppIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t('pages.certificates.eventSearch.recreateCausedName')}`} aria-label="edit">
            <IconButton aria-label="edit" size="small" onClick={() => setCertificationDialogItem(certification)}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {hasRequiredGroup([ALLOWED_GROUPS.SUPERUSER], groups) && (
            <DialogConfirmation
              confirmCallback={() => deleteCertificateItem(certification.certificateId)}
              confirmText={t('pages.certificates.eventSearch.deleteTB')}
              cancelText={t('pages.certificates.editDialog.cancel')}
              dialogTitle={t('pages.certificates.logs.reallyDelete')}
            >
              <DeleteIcon />
            </DialogConfirmation>
          )}
        </>
      )
    );
  };

  const requestStatusIssued = t('pages.certificates.eventSearch.STATUS_ISSUED');
  const requestStatusNotAvailable = t('pages.certificates.eventSearch.STATUS_NOT_AVAILABLE');
  const requestStatusRequestable = t('pages.certificates.eventSearch.STATUS_REQUESTABLE');

  const getRequestStatusTranslation = (status?: CertificationStatus) => {
    if (status) {
      return status === 'REQUESTABLE' ? requestStatusRequestable : requestStatusNotAvailable;
    }

    if (certificationStatus?.requestStatus === 'REQUESTABLE') {
      return requestStatusRequestable;
    }

    return requestStatusNotAvailable;
  };

  return (
    <TableContainer className={classes.certificateTable}>
      <Table size="small" aria-label="sticky table" data-testid="certificate-table">
        <TableHead>
          <TableRow>
            <TableCell key="certificates-name" align="left" style={{ minWidth: '200' }}>
              {t('pages.certificates.eventSearch.tableHeaders.name')}
            </TableCell>
            <TableCell key="certificates-request-status" align="left" style={{ minWidth: '200' }}>
              {t('pages.certificates.eventSearch.tableHeaders.certificateRequestStatus')}
            </TableCell>
            <TableCell key="certificates-issue-date" align="left" style={{ minWidth: '200' }}>
              {t('pages.certificates.eventSearch.tableHeaders.createDate')}
            </TableCell>
            <TableCell key="certificates-actions" align="left" style={{ minWidth: '50' }}>
              {t('pages.certificates.eventSearch.tableHeaders.actions')}
            </TableCell>
            <TableCell key="certificates-update-date" align="left" style={{ minWidth: '50' }}>
              {t('pages.certificates.eventSearch.tableHeaders.updateDate')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certifications.map((certificate: ICertificationTableItem, i: number) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={`tb-logs-row-${i}`}>
                {'certificateId' in certificate ? (
                  <>
                    <TableCell key={`certificates-row-${i}-name`} width="200">
                      {certificate.name}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-issued`} width="200">
                      {requestStatusIssued}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-issueDate`} width="200">
                      {format(parseISO(certificate.issueDate), 'dd.MM.yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-actions`} width="200">
                      {certificateTableActions(certificate)}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-updateAt`} width="200">
                      {format(parseISO(certificate.updateAt), 'dd.MM.yyyy HH:mm:ss')}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell key={`certificates-row-${i}-name`} width="200">
                      {certificate.userid}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-issued`} width="200">
                      {certificationStatusRequestStatus !== requestStatus.LOADING &&
                      certificationStatus?.requestStatus !== 'NOT_REQUESTABLE' &&
                      !certificate.requestStatus ? (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={lernstandStatusLoading === requestStatus.LOADING}
                          onClick={() => getLernstandServiceStatus(certificate.userid)}
                        >
                          {t('pages.certificates.eventSearch.tableHeaders.requestStatus')}
                        </Button>
                      ) : (
                        getRequestStatusTranslation(certificate.requestStatus)
                      )}
                    </TableCell>
                    <TableCell key={`certificates-row-${i}-issueDate`} width="200"></TableCell>
                    <TableCell key={`certificates-row-${i}-actions`} width="200"></TableCell>
                    <TableCell key={`certificates-row-${i}-updateAt`} width="200"></TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CertificateTable;
