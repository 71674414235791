import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateLehrgangCertificateParams,
  ICreateUploadedLehrgangCertificateParams,
  IUpdateLehrgangCertificateParams,
  getApiService,
} from '../api/api-request';
import { IAlertContext } from '../components/AlertSnackbar/AlertContext';
import { ICertificationResponse } from '@shared/interfaces/src/certification';
import { GetJWTResult } from '../types';

interface IDeleteCertificatetMutationParams {
  lehrgangId: string;
  certificateId: string;
}

const ROOT_KEY = 'certificates';

const certificateKeys = {
  lehrgangList: (lehrgangId: string) => [ROOT_KEY, lehrgangId] as const,
};

export const useLehrgangCertificatesQuery = (getJWT: () => Promise<GetJWTResult>, lehrgangId: string) => {
  return useQuery({
    queryKey: certificateKeys.lehrgangList(lehrgangId),
    queryFn: () => getApiService(getJWT).getLehrgangCertificates(lehrgangId),
  });
};

export const useDeleteCertificateMutation = (
  getJWT: () => Promise<GetJWTResult>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) => {
  return useMutation({
    mutationKey: ['delete-certificate'],
    mutationFn: (params: IDeleteCertificatetMutationParams) =>
      getApiService(getJWT).deleteCertificate(params.certificateId),
    onMutate: async ({ certificateId, lehrgangId }) => {
      await queryClient.cancelQueries({ queryKey: certificateKeys.lehrgangList(lehrgangId) });
      const previousCertificates = queryClient.getQueryData(certificateKeys.lehrgangList(lehrgangId));
      queryClient.setQueryData(certificateKeys.lehrgangList(lehrgangId), (old: ICertificationResponse[]) =>
        [...(old ?? [])].filter((item) => item.id !== certificateId)
      );
      return { previousCertificates };
    },
    onError: (_err, { lehrgangId }, context) => {
      context && queryClient.setQueryData(certificateKeys.lehrgangList(lehrgangId), context.previousCertificates);
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'pages.certificates.createCertificate.deleteCertificate.error',
      });
    },
    onSuccess: () => {
      alertContext.enqueueAlert({
        type: 'success',
        i18nKey: 'pages.certificates.createCertificate.deleteCertificate.success',
      });
    },
    onSettled: (_d, _e, { lehrgangId }) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.lehrgangList(lehrgangId) });
    },
  });
};

export const useCreateCertificateMutation = (
  getJWT: () => Promise<GetJWTResult>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) => {
  return useMutation({
    mutationFn: (params: ICreateLehrgangCertificateParams) => getApiService(getJWT).createLehrgangCertificate(params),
    onSettled: (_d, _e, { lehrgangId }) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.lehrgangList(lehrgangId) });
    },
    onError: () => {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'pages.certificates.createCertificate.createCertificate.error',
      });
    },
    onSuccess: () => {
      alertContext.enqueueAlert({
        type: 'success',
        i18nKey: 'pages.certificates.createCertificate.createCertificate.success',
      });
    },
  });
};

export const useCreateUploadedCertificateMutation = (
  getJWT: () => Promise<GetJWTResult>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) => {
  return useMutation({
    mutationFn: (params: ICreateUploadedLehrgangCertificateParams) =>
      getApiService(getJWT).createUploadedLehrgangCertificate(params),
    onSettled: (_d, _e, { lehrgangId }) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.lehrgangList(lehrgangId) });
    },
    onError: () => {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'pages.certificates.createCertificate.createCertificate.error',
      });
    },
    onSuccess: () => {
      alertContext.enqueueAlert({
        type: 'success',
        i18nKey: 'pages.certificates.createCertificate.createCertificate.success',
      });
    },
  });
};

export const useUpdateCertificateMutation = (getJWT: () => Promise<GetJWTResult>, queryClient: QueryClient) => {
  return useMutation({
    mutationFn: (params: IUpdateLehrgangCertificateParams) => getApiService(getJWT).updateLehrgangCertificate(params),
    onSettled: (_d, _e, { lehrgangId }) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.lehrgangList(lehrgangId) });
    },
  });
};

export const useCreateCertificateUploadUrlMutation = (getJWT: () => Promise<GetJWTResult>) => {
  return useMutation({
    mutationFn: () => getApiService(getJWT).createCertificateUploadUrl(),
  });
};
