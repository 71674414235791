import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';
import { GetJWTResult } from '../types';

const ROOT_KEY = 'bookings';

const bookingKeys = {
  lehrgangBookings: (lehrgangId: string) => [ROOT_KEY, 'lehrgang', lehrgangId] as const,
};

export const useLehrgangBookingsQuery = (getJWT: () => Promise<GetJWTResult>, lehrgangId: string) => {
  return useQuery({
    queryKey: bookingKeys.lehrgangBookings(lehrgangId),
    queryFn: async () => await getApiService(getJWT).getLehrgangBookings(lehrgangId),
  });
};
