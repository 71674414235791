import {
  CertificationStatus,
  CooperationPartner,
  ICertificationLogItemResponse,
  ICertificationResponse,
  ICertificationStatusDisplayContent,
  ICertificationTableItem,
  ICreateCertificateUploadUrlResponse,
  IUpdateCertificationRequestBody,
} from '@shared/interfaces/src/certification';
import mergedConfig from '../config';
import { ICertificationUrlResponse, ILehrgangBookingResponse, IUserResponse } from '@shared/interfaces';
import { GetJWTResult } from '../types';

export class CockpitApiError extends Error {
  errorCode?: string;

  constructor(message: string, errorCode?: string) {
    super(message);
    this.errorCode = errorCode;
  }
}

export interface ICreateUploadedLehrgangCertificateParams {
  lehrgangId: string;
  hmguserid: string;
  fileKey: string;
}

export interface ICreateLehrgangCertificateParams {
  lehrgangId: string;
  hmguserid: string;
  issueDate: string;
  evaluationText: string;
  score: string;
  evaluationRanges: string;
  cooperationPartner: CooperationPartner | '';
  preview: boolean;
}

export interface IUpdateLehrgangCertificateParams extends Omit<ICreateLehrgangCertificateParams, 'preview'> {
  title: string;
  firstName: string;
  lastName: string;
}

export const getApiService = (getJWT: () => Promise<GetJWTResult>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: Record<string, any>,
    params?: Record<string, string>
  ) => {
    const jwt = await getJWT();
    if (!jwt) {
      throw new Error('Could not get JWT');
    }

    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: jwt,
      },
    });

    if (result.status >= 400) {
      const data = await result.json();

      throw new CockpitApiError('Cockpit API request failed', data?.errorCode);
    }

    return await result.text();
  };

  const createLehrgangCertificate = async (
    params: ICreateLehrgangCertificateParams
  ): Promise<string | ICertificationResponse> => {
    const { lehrgangId, hmguserid, issueDate, cooperationPartner, evaluationRanges, evaluationText, score, preview } =
      params;
    const result = await apiRequest(
      `lehrgang-certificate/${lehrgangId}/${hmguserid}`,
      'PUT',
      {
        issueDate,
        cooperationPartner,
        evaluationText,
        score,
        evaluationRanges,
      },
      { preview: `${preview}` }
    );
    return preview ? result : (JSON.parse(result) as ICertificationResponse);
  };

  const getCertificateUrl = async (certificateId: string): Promise<ICertificationUrlResponse | null> => {
    const data = await apiRequest(`certificate-url/${certificateId}`, 'GET');

    return JSON.parse(data) as ICertificationUrlResponse | null;
  };

  const getCertificationData = async (hmguserid: string, eventId?: string): Promise<ICertificationResponse[]> => {
    const response = await apiRequest(`users/${hmguserid}/certificates`, 'GET', undefined, {
      ...(eventId && { eventId }),
    });

    return JSON.parse(response) as ICertificationResponse[];
  };

  const getCertificatePreview = async (content: string, type: string): Promise<string> => {
    const response = await apiRequest(`certificate-preview`, 'POST', { content, type });

    return response;
  };

  const getCertificationPreview = async (bookingNumber: string): Promise<string> => {
    const response = await apiRequest(`certificate-preview/${bookingNumber}`, 'GET');

    return response;
  };

  const getCertificationStatusForLernstandService = async (
    hmguserid: string,
    eventId: string
  ): Promise<CertificationStatus> => {
    const certificates = await getCertificationData(hmguserid, eventId);
    return certificates[0] ? certificates[0].status : 'NOT_AVAILABLE';
  };

  const getEventCertificationStatus = async (eventId: string): Promise<ICertificationStatusDisplayContent> => {
    const response = await apiRequest(`events/${eventId}/certification-status`, 'GET');

    return JSON.parse(response) as ICertificationStatusDisplayContent;
  };

  const getEventCertificateLogItems = async (eventId: string): Promise<ICertificationLogItemResponse[]> => {
    const response = await apiRequest(`certificate-logs`, 'GET', undefined, { eventId });

    return JSON.parse(response) as ICertificationLogItemResponse[];
  };

  const getEventCertificationTableItems = async (eventId: string): Promise<ICertificationTableItem[]> => {
    const response = await apiRequest(`events/${eventId}/certification-table`, 'GET');

    return JSON.parse(response) as ICertificationTableItem[];
  };

  const getLehrgangBookings = async (lehrgangId: string): Promise<ILehrgangBookingResponse[]> => {
    const lehrgangBookings = await apiRequest(`lehrgang-bookings/${lehrgangId}`, 'GET');

    return JSON.parse(lehrgangBookings) as ILehrgangBookingResponse[];
  };

  const getLehrgangCertificates = async (lehrgangId: string): Promise<ICertificationResponse[]> => {
    const response = await apiRequest(`lehrgang/${lehrgangId}/certificates`, 'GET');

    return JSON.parse(response) as ICertificationResponse[];
  };

  const getUserData = async (userId: string): Promise<IUserResponse> => {
    const userData = await apiRequest(`user-data/${userId}`, 'GET');

    return JSON.parse(userData) as IUserResponse;
  };

  const deleteCertificate = async (id: string): Promise<void> => {
    await apiRequest(`certificates/${id}`, 'DELETE');
  };

  const updateCertificationContent = async (eventId: string): Promise<void> => {
    await apiRequest(`certification-content/${eventId}`, 'PUT');
  };

  const updateLehrgangCertificate = async (
    params: IUpdateLehrgangCertificateParams
  ): Promise<string | ICertificationResponse> => {
    const { lehrgangId, hmguserid, ...rest } = params;

    const data = await apiRequest(`lehrgang-certificate/${lehrgangId}/${hmguserid}`, 'PATCH', rest);

    return JSON.stringify(data) as string | ICertificationResponse;
  };

  const updateUserEventCertificateData = async (
    hmguserid: string,
    eventid: string,
    data: IUpdateCertificationRequestBody
  ): Promise<void> => {
    await apiRequest(`users/${hmguserid}/${eventid}/certificates`, 'POST', data);
  };

  const createCertificateUploadUrl = async (): Promise<ICreateCertificateUploadUrlResponse> => {
    const data = await apiRequest(`certificate-upload-url`, 'POST');

    return JSON.parse(data) as ICreateCertificateUploadUrlResponse;
  };

  const createUploadedLehrgangCertificate = async (
    params: ICreateUploadedLehrgangCertificateParams
  ): Promise<string | ICertificationResponse> => {
    const { lehrgangId, hmguserid, fileKey } = params;
    const result = await apiRequest(`lehrgang-certificate/${lehrgangId}/${hmguserid}/${fileKey}`, 'PUT');
    return JSON.parse(result) as ICertificationResponse;
  };

  return {
    createCertificateUploadUrl,
    createLehrgangCertificate,
    createUploadedLehrgangCertificate,
    getCertificatePreview,
    getCertificationData,
    getCertificationPreview,
    getCertificationStatusForLernstandService,
    getCertificateUrl,
    getEventCertificateLogItems,
    getEventCertificationStatus,
    getEventCertificationTableItems,
    getLehrgangBookings,
    getLehrgangCertificates,
    getUserData,
    deleteCertificate,
    updateCertificationContent,
    updateLehrgangCertificate,
    updateUserEventCertificateData,
  };
};
