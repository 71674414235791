import { Button, IconButton, LinearProgress, Skeleton, TableCell, TableRow, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import GetAppIcon from '@mui/icons-material/GetApp';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ALLOWED_GROUPS, hasRequiredGroup } from "@hmg-aka-lu/cockpit-frontend/dist-types/exports";
import { getApiService } from "../../api/api-request";
import MicroFrontendContext from "../MicroFrontendContext";
import { useDeleteCertificateMutation } from "../../queries/certificates";
import { useQueryClient } from "@tanstack/react-query";
import { ICertificationResponse } from "@shared/interfaces/src/certification";
import { AlertContext } from "../AlertSnackbar/AlertContext";
import UploadFileIcon from '@mui/icons-material/UploadFileRounded';
import { CreateMode } from "./BookingTable";
import InfoIcon from '@mui/icons-material/Info';

interface IBookingRowProps {
  hmguserid: string
  certificatesLoading: boolean

  certificate?: ICertificationResponse

  onCreate: (createMode: CreateMode,hmguserId: string) => void
}

const BookingRow: FunctionComponent<IBookingRowProps> = ({hmguserid, certificate, certificatesLoading, onCreate}) => {

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { t } = useTranslation();

  const { getJWT, groups } = useContext(MicroFrontendContext);
  const queryClient = useQueryClient();
  const alertContext = useContext(AlertContext);
  const { mutate: deleteCertificate, isPending: deletingCertificate } = useDeleteCertificateMutation(getJWT, queryClient, alertContext);

  const downloadCertificate = async (certifcateId: string) => {
    const apiService = getApiService(getJWT);
    try {
      const certificateUrl = await apiService.getCertificateUrl(certifcateId);
      if (certificateUrl) {
        window.open(certificateUrl.url, '_blank');
      }
    } catch (error) {
  
    }
  }

  const showButtons = !certificatesLoading && !deletingCertificate;

  const handleDeleteCertificate = async (lehrgangId: string, certificateId: string) => {
    await deleteCertificate({certificateId, lehrgangId})
    setShowDeleteConfirmation(false);
  }

  const canCreateCertificate = hasRequiredGroup([ALLOWED_GROUPS.CERTIFICATES_WRITE], groups)
  const canDeleteCertificate = hasRequiredGroup([ALLOWED_GROUPS.SUPERUSER], groups);

  const buttonsForExistingCertificate = (certificate: ICertificationResponse) => {
    return <>
      {showDeleteConfirmation ? <>
        <Button data-testid={`perform-delete-certificate-button-${hmguserid}`} style={{ margin: 6}}onClick={() => handleDeleteCertificate(certificate.parentId, certificate.id)} color="secondary" variant="contained">{t('pages.certificates.createCertificate.bookingTable.delete')}</Button>
        <Button data-testid={`cancel-delete-certificate-button-${hmguserid}`} style={{margin: 6}} onClick={() => setShowDeleteConfirmation(false)} variant="outlined">{t('pages.certificates.createCertificate.bookingTable.cancel')}</Button>
      </> : <>
        <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.download')}`}>
          <IconButton data-testid={`download-certificate-button-${hmguserid}`} onClick={() => downloadCertificate(certificate.id)}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        {certificate.type === 'CERTIFICATE' ? <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.edit')}`}>
          <IconButton data-testid={`edit-certificate-button-${hmguserid}`}  onClick={() => onCreate('form', hmguserid)}>
            <EditIcon/>
          </IconButton>
        </Tooltip> : <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.infoManualUpload')}`}>
          <IconButton data-testid={`edit-certificate-info-button-${hmguserid}`} >
            <InfoIcon />
          </IconButton>
        </Tooltip>}
        {canDeleteCertificate && <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.delete')}`}>
          <IconButton data-testid={`delete-certificate-button-${hmguserid}`} onClick={() => setShowDeleteConfirmation(true)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </Tooltip>}
      </>}
   </>
  }

  const buttonsForNewCertificate = (hmguserid: string) => {
    return <>
      <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.createCertificate')}`}>
        <span>
          <IconButton data-testid={`create-certificate-button-${hmguserid}`} disabled={!canCreateCertificate} onClick={() => onCreate('form', hmguserid)}>
            <NoteAddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={`${t('pages.certificates.createCertificate.bookingTable.uploadCertificate')}`}>
        <span>
          <IconButton data-testid={`upload-certificate-button-${hmguserid}`} disabled={!canCreateCertificate} onClick={() => onCreate('upload', hmguserid)}>
            <UploadFileIcon />
          </IconButton>
        </span>
      </Tooltip>
   </>
  }

  return  <TableRow hover role="checkbox" tabIndex={-1} key={`tb-logs-row-${hmguserid}`}>
    <TableCell key={`certificates-row-${hmguserid}-name`} data-testid={`certificates-row-${hmguserid}-name`} width="200">
      {`${hmguserid}`}
    </TableCell>

    <TableCell key={`certificates-row-${hmguserid}-date`} data-testid={`certificates-row-${hmguserid}-date`} width="200">
      { certificatesLoading && <Skeleton width={70} variant="text" /> }
      { !certificatesLoading && (certificate?.issueDate 
        ? format(new Date(certificate.issueDate), 'dd.MM.yyyy') 
        : '')}
    </TableCell>

    <TableCell key={`certificates-row-${hmguserid}-update-date`} data-testid={`certificates-row-${hmguserid}-update-date`} width="200">
      { certificatesLoading &&  <Skeleton width={130} variant="text" /> }
      { !certificatesLoading && (certificate?.updateDate ? format(new Date(certificate.updateDate), 'dd.MM.yyyy HH:mm:ss') : '')}
    </TableCell>

    <TableCell key={`certificates-row-${hmguserid}-actions`} data-testid={`certificates-row-${hmguserid}-actions`} width="200">
      { !showButtons && <LinearProgress data-testid={`linear-progress-actions-${hmguserid}`} /> }
      { showButtons && (certificate 
        ? buttonsForExistingCertificate(certificate)
        : buttonsForNewCertificate(hmguserid)
      )}
    </TableCell>
  
  </TableRow>;
}

export default BookingRow;