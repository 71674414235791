import { Alert, Button, Grid, LinearProgress, Paper, Box, TextField, Theme, AppBar, Tabs, Tab } from '@mui/material';
import TabPanel from '../TabPanel';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TBRecreateName from '../TBRecreateName';
import CertificateTable from './CertificateTable';
import LogsTable from './LogsTable';
import CertificateStatus from './CertificateStatus';
import ColorPalette from '@packages/lighthouse-theme/src/ColorPalette';
import { createStyles, makeStyles } from '@mui/styles';
import {
  ICertificationLogItemResponse,
  ICertificationStatusDisplayContent,
  ICertificationTableItem,
  IIssuedCertificationTableItem,
} from '@shared/interfaces';
import MicroFrontendContext from '../../MicroFrontendContext';
import { getApiService } from '../../../api/api-request';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    resultContainer: {
      borderTop: '1px dashed',
      borderColor: ColorPalette.monochrome.grey60,
    },
    formContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);

export enum requestStatus {
  INITIAL,
  LOADING,
  LOADED,
  ERROR,
}

const EventCheck: FunctionComponent = () => {
  const classes = useStyles();

  const [eventId, setEventId] = useState<string>('');
  const [certifications, setCertifications] = useState<ICertificationTableItem[]>([]);
  const [certificationDialogItem, setCertificationDialogItem] = useState<
    (ICertificationTableItem & IIssuedCertificationTableItem) | null
  >(null);
  const [certificationStatus, setCertificationStatus] = useState<ICertificationStatusDisplayContent | null>(null);
  const [logs, setLogs] = useState<ICertificationLogItemResponse[]>([]);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const [certificationStatusRequestStatus, setCertificationStatusRequestStatus] = useState<requestStatus>(
    requestStatus.INITIAL
  );
  const [certificationsRequestStatus, setCertificationsRequestStatus] = useState<requestStatus>(requestStatus.INITIAL);
  const [logsRequestStatus, setLogsRequestStatus] = useState<requestStatus>(requestStatus.INITIAL);
  const [lernstandRequestStatus, setLernstandRequestStatus] = useState<requestStatus>(requestStatus.INITIAL);

  const { getJWT } = useContext(MicroFrontendContext);

  const handleEventIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventId(event.target.value);
  };

  const showResultsContainer = () => {
    return (
      certificationsRequestStatus !== requestStatus.INITIAL &&
      logsRequestStatus !== requestStatus.INITIAL &&
      certificationStatus?.statusType !== 'FATAL_ERROR'
    );
  };

  const getCertifications = async (eventId: string): Promise<void> => {
    setCertificationsRequestStatus(requestStatus.LOADING);
    try {
      const apiService = getApiService(getJWT);
      const certifications = await apiService.getEventCertificationTableItems(eventId);
      setCertifications(certifications);
      setCertificationsRequestStatus(requestStatus.LOADED);
    } catch (error) {
      setCertificationsRequestStatus(requestStatus.ERROR);
    }
  };

  const getLernstandServiceStatus = async (userId: string) => {
    setLernstandRequestStatus(requestStatus.LOADING);
    try {
      const apiService = getApiService(getJWT);
      const status = await apiService.getCertificationStatusForLernstandService(userId, eventId);
      setCertifications(
        certifications.map((item) => {
          if (item.userid === userId) {
            return {
              ...item,
              requestStatus: status,
            };
          }
          return item;
        })
      );
    } catch (error) {
      setLernstandRequestStatus(requestStatus.ERROR);
    } finally {
      setLernstandRequestStatus(requestStatus.LOADED);
    }
  };

  const generateCertificationContent = async (eventId: string) => {
    setCertificationStatusRequestStatus(requestStatus.LOADING);
    try {
      const apiService = getApiService(getJWT);
      await apiService.updateCertificationContent(eventId);
      await getCertificationStatus(eventId);
    } catch (error) {
      setCertificationStatusRequestStatus(requestStatus.ERROR);
    } finally {
      setCertificationStatusRequestStatus(requestStatus.LOADED);
    }
  };

  const getCertificationStatus = async (eventId: string): Promise<void> => {
    setCertificationStatusRequestStatus(requestStatus.LOADING);
    try {
      const apiService = getApiService(getJWT);
      const certificationStatus = await apiService.getEventCertificationStatus(eventId);
      setCertificationStatus(certificationStatus);
      setCertificationStatusRequestStatus(requestStatus.LOADED);
    } catch (error) {
      setCertificationStatusRequestStatus(requestStatus.ERROR);
    }
  };

  const getLogs = async (eventId: string): Promise<void> => {
    setLogsRequestStatus(requestStatus.LOADING);
    try {
      const apiService = getApiService(getJWT);
      const logItems = await apiService.getEventCertificateLogItems(eventId);
      setLogs(logItems);
      setLogsRequestStatus(requestStatus.LOADED);
    } catch (error) {
      setLogsRequestStatus(requestStatus.ERROR);
      setLogs([]);
    }
  };

  const onEditDialogCloseCallback = () => {
    setCertificationDialogItem(null);
  };

  const onSaveCallback = (certificationItem: ICertificationTableItem) => {
    setCertifications(
      certifications.map((item) => {
        if (item.userid === certificationItem.userid) {
          return certificationItem;
        }

        return item;
      })
    );
    setCertificationDialogItem(null);
  };

  const handleSubmit = () => {
    if (eventId) {
      setCertifications([]);
      setCertificationStatus(null);
      setLogs([]);
      getCertificationStatus(eventId);
      getCertifications(eventId);
      getLogs(eventId);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleGenerateContent = () => {
    generateCertificationContent(eventId);
  };

  const certificateTable = (
    <CertificateTable
      certifications={certifications}
      getLernstandServiceStatus={getLernstandServiceStatus}
      certificationStatus={certificationStatus}
      certificationStatusRequestStatus={certificationStatusRequestStatus}
      lernstandStatusLoading={lernstandRequestStatus}
      setCertificationDialogItem={setCertificationDialogItem}
    />
  );

  const logsTable = <LogsTable logs={logs} />;

  const statusTable = (
    <CertificateStatus certificationStatus={certificationStatus} generateCertificationContent={handleGenerateContent} />
  );

  return (
    <Paper className={classes.root}>
      <Box component="div" className={classes.resultContainer} mt={3} pt={2} mb={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1 },
              }}
              noValidate
              autoComplete="off"
              justifyContent="flex-end"
            >
              <TextField
                required
                data-testid="va-id-input"
                id="va-id-input"
                label="VA-ID"
                size="small"
                margin="dense"
                onChange={handleEventIdChange}
              />
              <Button data-testid="va-search-button" variant="contained" color="primary" onClick={handleSubmit}>
                {t('pages.certificates.eventSearch.search')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {certificationStatusRequestStatus === requestStatus.LOADING && <LinearProgress />}
            {certificationStatusRequestStatus === requestStatus.LOADED && statusTable}
          </Grid>
        </Grid>
        <Box mt={2}>
          {certificationStatusRequestStatus === requestStatus.LOADED &&
            certificationStatus?.statusType === 'FATAL_ERROR' && (
              <Alert severity="error">{t('pages.certificates.eventSearch.noEventData')}</Alert>
            )}
          {certificationStatusRequestStatus === requestStatus.ERROR && (
            <Alert severity="error">{t('pages.certificates.eventSearch.errors.status')}</Alert>
          )}
        </Box>
      </Box>

      {showResultsContainer() && (
        <Box data-testid="results-tabs">
          <AppBar position="static" color="default" elevation={1} style={{ marginBottom: '20px' }}>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={t('pages.certificates.eventSearch.certificateTableHeader')}
                id="scrollable-auto-tab-0"
                aria-controls="scrollable-auto-tabpanel-0"
              />
              <Tab label="Logs" id="scrollable-auto-tab-1" aria-controls="scrollable-auto-tabpanel-1" />
            </Tabs>
          </AppBar>
          <TabPanel value={tab} index={0}>
            {(certificationsRequestStatus === requestStatus.LOADING ||
              lernstandRequestStatus === requestStatus.LOADING) && <LinearProgress />}
            {certificationsRequestStatus === requestStatus.ERROR && (
              <Alert severity="error">{t('pages.certificates.eventSearch.errors.certificates')}</Alert>
            )}
            {certificationsRequestStatus === requestStatus.LOADED &&
              (certifications.length === 0 ? (
                <Alert style={{ marginTop: 2 }} severity="info">
                  {t('pages.certificates.eventSearch.noCertificates')}
                </Alert>
              ) : (
                certificateTable
              ))}
            {certificationDialogItem && (
              <TBRecreateName
                certificationItem={certificationDialogItem}
                vaId={eventId}
                onSaveCallback={onSaveCallback}
                onCloseCallback={onEditDialogCloseCallback}
              />
            )}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {logsRequestStatus === requestStatus.LOADING && <LinearProgress />}
            {logsRequestStatus === requestStatus.ERROR && (
              <Alert severity="error">{t('pages.certificates.eventSearch.errors.logs')}</Alert>
            )}
            {logsRequestStatus === requestStatus.LOADED &&
              (logs.length === 0 ? (
                <Alert severity="info">{t('pages.certificates.eventSearch.noLogs')}</Alert>
              ) : (
                logsTable
              ))}
          </TabPanel>
        </Box>
      )}
    </Paper>
  );
};

export default EventCheck;
