import { Alert, AlertTitle, Autocomplete, Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ICertificationResponse } from "@shared/interfaces/src/certification";
import { makeStyles } from "@mui/styles";
import MicroFrontendContext from "../MicroFrontendContext";
import { useUserDataQuery } from "../../queries/users";
import { useCreateCertificateMutation, useUpdateCertificateMutation } from "../../queries/certificates";
import { useQueryClient } from "@tanstack/react-query";
import { CockpitApiError } from "../../api/api-request";
import { AlertContext } from "../AlertSnackbar/AlertContext";

const CooperationPartnerKeys = ['HDWM', 'OTHAW', 'THD'] as const;
export type CooperationPartner = typeof CooperationPartnerKeys[number];

interface ICreateCertificateFormProps {
  hmguserid: string;
  lehrgangId: string;
  certificate?: ICertificationResponse;
  onCancelCallback: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '10%',
    height: '100%',
  },
  heading: {
    marginBottom: 30
  },
  buttons: {
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 10
    }
  },
  userData: {
    fontWeight: 'bold'
  }
  
}));

interface IInitialValues {
  issueDate: string;
  evaluationText: string;
  score: string;
  evaluationRanges: string;
  cooperationPartner: CooperationPartner | '';
  title: string;
  firstName: string;
  lastName: string;
}

const CreateCertificateForm: FunctionComponent<ICreateCertificateFormProps> = ({ hmguserid, lehrgangId, onCancelCallback, certificate }) => {

  const { t } = useTranslation();

  const MODE = certificate ? 'EDIT' : 'CREATE';

  const initialValues: IInitialValues = certificate ? {
    issueDate: format(new Date(certificate.issueDate), 'yyyy-MM-dd'),
    evaluationText: certificate.evaluationText ?? '',
    score: certificate.evaluation ?? '',
    evaluationRanges: certificate.ratingKeys ?? '',
    cooperationPartner: certificate.cooperationPartner as CooperationPartner ?? '',
    title: certificate.title ?? '',
    firstName: certificate.firstName,
    lastName: certificate.lastName,
  } : {
    issueDate: format(new Date(), 'yyyy-MM-dd'),
    evaluationText: '',
    score: '',
    evaluationRanges: '',
    cooperationPartner: '',
    title: '',
    firstName: '',
    lastName: '',
  }

  const [issueDate, setIssueDate] = useState(initialValues.issueDate);
  const [evaluationText, setEvaluationText] = useState(initialValues.evaluationText);
  const [score, setScore] = useState(initialValues.score);
  const [evaluationRanges, setEvaluationRanges] = useState(initialValues.evaluationRanges);
  const [cooperationPartner, setCooperationPartner] = useState<CooperationPartner | ''>(initialValues.cooperationPartner);
  const [title, setTitle] = useState<string>(initialValues.title);
  const [firstName, setFirstName] = useState(initialValues.firstName);
  const [lastName, setLastName] = useState(initialValues.lastName);
  const [loading, setLoading] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const { getJWT } = useContext(MicroFrontendContext);

  const queryClient = useQueryClient();
  const alertContext = useContext(AlertContext);

  const { data: userData } = useUserDataQuery(getJWT, hmguserid);
  const { 
    reset: resetCreateCertificate, 
    mutate: createCertificate, 
    data: createdCertificate,
    isError: errorCreatingCertificate,
    error: createCertificateError, 
  } = useCreateCertificateMutation(getJWT, queryClient, alertContext)

  const {
    mutate: updateCertificate, 
  } = useUpdateCertificateMutation(getJWT, queryClient)

  const classes = useStyles();

  const EVALUATION_TEXT_PRESETS = [
    'mit sehr gutem Erfolg abgeschlossen',
    'mit sehr gutem Erfolg bestanden',
    'mit gutem Erfolg abgeschlossen',
    'mit gutem Erfolg bestanden',
    'mit Erfolg abgeschlossen',
    'mit Erfolg bestanden',
    'abgeschlossen',
    'bestanden',

  ];

  const SCORE_TEXT_PRESETS = [
    'Erreichte Punkte in der E-Prüfung: XX von XX Punkten',
  ];

  const EVALUATION_RANGES_PRESETS = [
    'Qualifikationsstufen:\nXX-XX richtig beantwortete Fragen: mit sehr gutem Erfolg abgeschlossen\nXX-XX richtig beantwortete Fragen: mit gutem Erfolg abgeschlossen\nXX-XX richtig beantwortete Fragen: mit Erfolg abgeschlossen\nXX-XX richtig beantwortete Fragen: abgeschlossen',
    'Qualifikationsstufen:\nXX-XX richtig beantwortete Fragen: mit sehr gutem Erfolg bestanden\nXX-XX richtig beantwortete Fragen: mit gutem Erfolg bestanden\nXX-XX richtig beantwortete Fragen: mit Erfolg bestanden\nXX-XX richtig beantwortete Fragen: bestanden',
  ]

  const certificateCanBeCreated = issueDate;

  const handleShowPreview = async () => {
    setLoading(true);
    setShowPreview(true);
    createCertificate({
      lehrgangId,
      hmguserid,
      issueDate,
      evaluationText,
      score,
      evaluationRanges,
      cooperationPartner,
      preview: true
    },{
      onSettled: () => setLoading(false),
    })
  }

  const handleCreateCertificate = async () => {
    setLoading(true);
    createCertificate({
      lehrgangId,
      hmguserid,
      issueDate,
      evaluationText,
      score,
      evaluationRanges,
      cooperationPartner,
      preview: false
    }, {
      onSettled: () => setLoading(false),
      onSuccess: onCancelCallback
    });
  }

  const handleUpdateCertificate = async () => {
    setLoading(true);
    await updateCertificate({
      lehrgangId,
      hmguserid,
      issueDate,
      evaluationText,
      score,
      evaluationRanges,
      cooperationPartner,
      title,
      firstName,
      lastName
    }, {
      onSettled: () => setLoading(false),
      onSuccess: onCancelCallback
    });
  }

  const handleClosePreview = () => {
    setShowPreview(false);
    setEvaluationRanges(current => current);
  }

  const Form = <>
    <Typography className={classes.heading} variant="h5">
      {t('pages.certificates.createCertificateForm.header')}
    </Typography>

    <Grid container spacing={2}>

      <Grid className={classes.userData} container item xs={12}>
        <AccountBoxIcon color="disabled" /> { userData ? `${userData.title || ''} ${userData.firstname} ${userData.lastname}`.trim() : <Skeleton width={200}/>} - {hmguserid}
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="issueDate"
          type="date"
          value={issueDate}
          label={t('pages.certificates.createCertificateForm.labels.issueDate')}
          onChange={(e) => setIssueDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      { MODE === 'EDIT' && <>
        <Grid item xs={2}>
          <TextField
            id="title"
            value={title}
            label={t('pages.certificates.createCertificateForm.labels.title')}
            onChange={(e) => setTitle(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            id="firstName"
            value={initialValues.firstName}
            label={t('pages.certificates.createCertificateForm.labels.firstName')}
            onChange={(e) => setFirstName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            id="lastName"
            value={initialValues.lastName}
            label={t('pages.certificates.createCertificateForm.labels.lastName')}
            onChange={(e) => setLastName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
          
      </>}

      <Grid item xs={12}>
        <FormControl style={{width: '100%'}}>
          <InputLabel shrink id="cooperation-partner-label">
            {t('pages.certificates.createCertificateForm.labels.cooperationPartner')}
          </InputLabel>
          <Select
            label={t('pages.certificates.createCertificateForm.labels.cooperationPartner')}
            labelId="cooperation-partner-label"
            id="cooperation-partner"
            value={cooperationPartner}
            onChange={(e) => setCooperationPartner(e.target.value as CooperationPartner)}
            notched
            displayEmpty
          >
            <MenuItem key="coopertation-partner-empty" value="">
              <em>{t('pages.certificates.createCertificateForm.labels.noCooperationPartner')}</em>
            </MenuItem>
            { CooperationPartnerKeys.map(cooperationPartnerKey => (
              <MenuItem key={`coopertation-partner-${cooperationPartnerKey}`} value={cooperationPartnerKey}>{t(`pages.certificates.createCertificateForm.cooperationPartners.${cooperationPartnerKey}`)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          id="evaluationText"
          filterOptions={(options, _state) => {return options.filter(option => option.match(new RegExp(evaluationText)))}}
          options={EVALUATION_TEXT_PRESETS}
          onInputChange={(_e, value) => {
            setEvaluationText(value.trim())
          }}
          inputValue={evaluationText}
          value={evaluationText}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              label={t('pages.certificates.createCertificateForm.labels.evaluationText')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          id="score"
          disableClearable
          options={SCORE_TEXT_PRESETS}
          filterOptions={(options, _state) => {return options.filter(option => option.match(new RegExp(score)))}}
          onInputChange={(_e, value) => {
            setScore(value.trim())
          }}
          value={score}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              label={t('pages.certificates.createCertificateForm.labels.score')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          id="evaluationRanges"
          disableClearable
          options={EVALUATION_RANGES_PRESETS}
          filterOptions={(options, _state) => {return options.filter(option => option.match(new RegExp(evaluationRanges)))}}
          value={evaluationRanges}
          onInputChange={(_e, value) => {
            setEvaluationRanges(value.trim())
          }}
          renderInput={(params) => {
            return <TextField
              multiline={true}
              {...params}
              variant="outlined"
              label={t('pages.certificates.createCertificateForm.labels.evaluationRanges')}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps, type: 'search' }}
              minRows={8}
              value={evaluationRanges}
            />
          }}
        />
      </Grid>
      
      { loading && <Grid container item xs={12}>
        <LinearProgress color="primary" style={{ width: '100%'}} />
      </Grid> }
      
      <Grid className={classes.buttons} container item xs={12}>
      <Button onClick={onCancelCallback} disabled={loading} variant="outlined" color="secondary">{t('pages.certificates.createCertificateForm.buttons.cancel')}</Button>
        { MODE === 'CREATE' ? <>
          
          <Button onClick={handleShowPreview} disabled={!certificateCanBeCreated || loading} variant="outlined" color="primary">{t('pages.certificates.createCertificateForm.buttons.preview')}</Button>
          <Button onClick={handleCreateCertificate} disabled={!certificateCanBeCreated || loading} variant="contained" color="primary">{t('pages.certificates.createCertificateForm.buttons.create')}</Button>
         </> : <>
         
         <Button onClick={handleUpdateCertificate} disabled={loading} variant="contained" color="primary">{t('pages.certificates.createCertificateForm.buttons.update')}</Button>
         </> }
      </Grid>
    </Grid>
  </>

  const Preview = <>
    <Typography className={classes.heading} variant="h5">
      {t('pages.certificates.createCertificateForm.preview.header')}
    </Typography>

    <Button style={{marginBottom: 20}} onClick={handleClosePreview} variant="outlined" color="primary"><ArrowBackIosIcon fontSize="small" />{t('pages.certificates.createCertificateForm.preview.backButtonLabel')}</Button>

    { loading ? <LinearProgress /> :
    <Grid container spacing={2} style={{height: '100%'}}>
      <Grid item xs={12}>
        { createdCertificate ? <embed src={ `data:application/pdf;base64,${createdCertificate}#view=fitH` } type="application/pdf" width={'100%'} height="100%" /> : <>{t('pages.certificates.createCertificateForm.generatingPreview')}<LinearProgress /></>}
      </Grid>
    </Grid>
    }
  </>

  if (errorCreatingCertificate) {
    return <div className={classes.root}>
      <Alert severity="error" variant="standard">
        <AlertTitle>{t('pages.certificates.createCertificateForm.error.title')}</AlertTitle>
        {
          (createCertificateError as CockpitApiError).errorCode === 'MISSING_PRODUCT_DATA' && t('pages.certificates.createCertificateForm.error.missingProductData')
        }
      </Alert>
      <Button onClick={() => resetCreateCertificate()} variant="outlined" color="primary">
        {t('pages.certificates.createCertificateForm.error.closeButtonLabel')}
      </Button>
    </div>
  }

  return <div className={classes.root}>
    { showPreview ? Preview : Form}
  </div>
}

export default CreateCertificateForm;