import {  Drawer, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from "@mui/material";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingRow from "./BookingRow";
import CreateCertificateForm from "./CreateCertificateForm";
import MicroFrontendContext from "../MicroFrontendContext";
import { SelectChangeEvent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLehrgangBookingsQuery } from "../../queries/bookings";
import { useLehrgangCertificatesQuery } from "../../queries/certificates";
import { UploadCertificateForm } from "./UploadCertificateForm";

interface IBookingTableProps {
  lehrgangId: string
}

export type CreateMode = 'form' | 'upload' | undefined;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(5)
  },
  drawerContent: {
    padding: theme.spacing(5),
    height: '100%'
  },
  filter: {
    marginBottom: theme.spacing(5),
    flexDirection: 'row'
  }
}));

type StatusFilter = 'all' | 'pending' | 'issued'

const BookingTable: FunctionComponent<IBookingTableProps> = ({ lehrgangId }) => {

  const [createCertificateForHmguserid, setCreateCertificateForHmguserid] = useState('');
  const [userIdFilter, setUserIdFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('all');
  const [statusFilterOpen, setStatusFilterOpen] = useState(false);
  const [createMode, setCreateMode] = useState<CreateMode>(undefined);

  const { t } = useTranslation();

  const { getJWT } = useContext(MicroFrontendContext);

  const { data: lehrgangBookings, isLoading: lehrgangBookingsLoading } = useLehrgangBookingsQuery(getJWT, lehrgangId);
  const { data: certificates, isLoading: certificatesLoading } = useLehrgangCertificatesQuery(getJWT, lehrgangId);

  const classes = useStyles();

  const onCancelCallback = () => {
    setCreateCertificateForHmguserid('');
  }

  const existingCertificate = certificates?.find(item => item.hmgUserId === createCertificateForHmguserid);

  const tableRows = lehrgangBookings?.filter(item => userIdFilter.length === 0 || item.hmguserid.match(new RegExp(`${userIdFilter}`)))
    .map(booking => {
      const certificate = certificates?.find(certificateItem => certificateItem.hmgUserId === booking.hmguserid)
      return {
        hmguserid: booking.hmguserid,
        certificate
      }
    }
  ).filter(item => statusFilter === 'all' || (statusFilter === 'issued' && item.certificate) || (statusFilter === 'pending' && !item.certificate));

  return <TableContainer className={classes.root} component={Paper}>
    <Typography variant="subtitle2">{t('pages.certificates.createCertificate.bookingTable.filters.header')}</Typography>
    <Grid container spacing={2} className={classes.filter}>
      <Grid container item xs={2}>
        <TextField
          required
          id="va-id-input"
          style={{width: '100%'}}
          label="HMGUSERID"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserIdFilter(e.target.value.trim())}
        />
      </Grid>
      <Grid container item xs={2}>
        <FormControl style={{width: '100%'}}>
          <InputLabel id="statusFilterLabel">{t('pages.certificates.createCertificate.bookingTable.filters.status.label')}</InputLabel>
          <Select
            notched={true}
            label={t('pages.certificates.createCertificate.bookingTable.filters.status.label')}
            labelId="statusFilterLabel"
            id="statusFilter"
            open={statusFilterOpen}
            onClose={() => setStatusFilterOpen(false)}
            onOpen={() => setStatusFilterOpen(true)}
            value={statusFilter}
            onChange={(e: SelectChangeEvent) => setStatusFilter(e.target.value as StatusFilter)}
          >
            <MenuItem value="all">{t('pages.certificates.createCertificate.bookingTable.filters.status.showAll')}</MenuItem>
            <MenuItem value="issued">{t('pages.certificates.createCertificate.bookingTable.filters.status.issued')}</MenuItem>
            <MenuItem value="pending">{t('pages.certificates.createCertificate.bookingTable.filters.status.pending')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    
    <Table size="small" stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
            <TableCell
              key="certificates-name"
              align="left"
              style={{ minWidth: "200"}}
            >
              { t('pages.certificates.createCertificate.bookingTable.headers.userId') }
            </TableCell>
            <TableCell
              key="certificates-issue-date"
              align="left"
              style={{ minWidth: "200" }}
            >
              { t('pages.certificates.createCertificate.bookingTable.headers.issuedAt') }
            </TableCell>
            <TableCell
              key="certificates-update-date"
              align="left"
              style={{ minWidth: "50" }}
            >
              { t('pages.certificates.createCertificate.bookingTable.headers.updatedAt') }
            </TableCell>
            <TableCell
              key="certificates-actions"
              align="left"
              style={{ minWidth: "50" }}
            >
              { t('pages.certificates.createCertificate.bookingTable.headers.actions') }
            </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { tableRows?.map((item) => <BookingRow 
          certificatesLoading={certificatesLoading || lehrgangBookingsLoading} 
          hmguserid={item.hmguserid} 
          certificate={item.certificate} 
          key={`row-${item.hmguserid}`} 
          onCreate={(createMode: CreateMode, hmguserid: string) => {
            setCreateMode(createMode);
            setCreateCertificateForHmguserid(hmguserid);
          }}
        />)}
      </TableBody>
    </Table>
    <Drawer PaperProps={{style: {width: '40%', height: '100%'}}} anchor="right" open={!!createCertificateForHmguserid} onClose={() => setCreateCertificateForHmguserid('')}>
      <div className={classes.drawerContent}>
        {createCertificateForHmguserid && createMode === 'form' && <CreateCertificateForm 
          onCancelCallback={onCancelCallback}
          hmguserid={createCertificateForHmguserid}
          lehrgangId={lehrgangId}
          certificate={existingCertificate}
          key={`create-certificate-form-${createCertificateForHmguserid}`}
        />}
        {createCertificateForHmguserid && createMode === 'upload' && <UploadCertificateForm 
          onCancelCallback={onCancelCallback}
          hmguserid={createCertificateForHmguserid}
          lehrgangId={lehrgangId}
          key={`upload-certificate-form-${createCertificateForHmguserid}`}
        />}
      </div>
    </Drawer>
  </TableContainer>
}

export default BookingTable;
