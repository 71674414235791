import { createContext } from 'react';
import { GetJWTResult } from '../types';

export interface IMicroFrontendContextProps {
  getJWT: () => Promise<GetJWTResult>;
  groups: string[];
  firstName?: string;
  lastName?: string;
}

const MicroFrontendContext = createContext<IMicroFrontendContextProps>({
  getJWT: async () => {
    return null;
  },
  groups: [],
});

export default MicroFrontendContext;
