import ReactDOM from 'react-dom/client';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import './i18n';
import '@packages/lighthouse-theme/src/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import MicroFrontendContext from './components/MicroFrontendContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AlertSnackBar } from './components/AlertSnackbar/AlertSnackbar';
import TBCockpitPage from './components/TBCockpit/TBCockpitPage';
import Certificates from './components/TBCockpit/Certificates';
declare global {
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', _history: any, microfrontendMeta) => {
  const queryClient = new QueryClient();

  const root = ReactDOM.createRoot(document.getElementById(containerId) as HTMLElement);
  const { groups, firstName, lastName } = microfrontendMeta.authenticatedContext

  return {
    render: () => {
      root.render(
        <LightHouseThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <MicroFrontendContext.Provider value={{ getJWT: microfrontendMeta.getJWT, groups, firstName, lastName }}>
              <BrowserRouter>
                <Routes>
                  <Route path={`/${MICROFRONTEND_ID}/search`} element={<TBCockpitPage />} />
                  <Route path={`/${MICROFRONTEND_ID}/certificates`} element={<Certificates />} />
                  <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/search`} />} />
                </Routes>
              </BrowserRouter>
              <AlertSnackBar />
            </MicroFrontendContext.Provider>
          </QueryClientProvider>
        </LightHouseThemeProvider>
      );
    },
  };
};

window.certificates = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {
        groups: ['Superuser']
      },
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
